export function colorConfigOptions (controller) {
  return [
    {
      color: controller.tailwindColors.blue[500],
      states: {
        hover: {
          color: controller.darkMode ? controller.tailwindColors.blue[400] : controller.tailwindColors.blue[600]
        }
      }
    },
    {
      color: controller.darkMode ? controller.tailwindColors.gray[400] : controller.tailwindColors.gray[300],
      states: {
        hover: {
          color: controller.darkMode ? controller.tailwindColors.gray[300] : controller.tailwindColors.gray[400]
        }
      }
    }
  ]
}

export function adoptionRateColorConfigOptions (controller) {
  return [
    {
      color: controller.darkMode ? controller.tailwindColors.blue[500] : controller.tailwindColors.blue[500],
      states: {
        hover: {
          color: controller.darkMode ? controller.tailwindColors.blue[400] : controller.tailwindColors.blue[600]
        }
      }
    },
    {
      color: controller.darkMode ? controller.tailwindColors.blue[800] : controller.tailwindColors.blue[200],
      states: {
        hover: {
          color: controller.darkMode ? controller.tailwindColors.blue[700] : controller.tailwindColors.blue[300]
        }
      }
    }
  ]
}

export function formatColumnSeries (data, colorConfig) {
  data.forEach((item, index) => {
    item.color = colorConfig[index].color
    item.states = colorConfig[index].states
  })

  return [{ data }]
}

export function formatSplineSeries (series, categories, colorConfig) {
  if (!series.length) return [{}]

  const zoneIndex = categories.length - 2
  series.forEach((item, index) => {
    item.zIndex = index === 0 ? 50 : 0
    item.color = colorConfig[index].color
    item.states = colorConfig[index].states
    item.zoneAxis = 'x'
    item.zones = [
      { value: zoneIndex }, // represents the first n periods
      { dashStyle: 'ShortDash' } // represents the last (current) period
    ]
  })
  return series
}

export function getTickPositions (categoryLength) {
  let tickPositions
  if (categoryLength >= 12) {
    // show only 5 labels
    categoryLength -= 1
    tickPositions = [0, Math.round(categoryLength * 0.25), Math.round(categoryLength * 0.50), Math.round(categoryLength * 0.75), categoryLength]
  }
  return tickPositions
}
