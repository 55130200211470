import BaseGraph from './base_graph_controller'
import { card, breakDownValue } from '../helpers/tooltip_helper'
import { colorConfigOptions, formatColumnSeries, formatSplineSeries, getTickPositions } from '../helpers/copilot_helper'

export default class extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    this.USDFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    })
    let data = []
    let series
    let categories = []
    let total = 0
    let plotLines = []
    if (controller.optionsValue.type === 'spline') {
      categories = controller.parsedGraphData.spline_categories
      data = controller.parsedGraphData.spline_data
      data.forEach((item) => {
        total += item.data.reduce((acc, value) => acc + value, 0)
      })
      series = formatSplineSeries(data, categories, colorConfigOptions(this))
      if (controller.parsedGraphData.copilot_adoption_date_index) {
        plotLines = [
          {
            color: controller.darkMode ? controller.tailwindColors.gray[400] : controller.tailwindColors.gray[300],
            value: controller.parsedGraphData.copilot_adoption_date_index,
            dashStyle: 'ShortDash',
            width: 2,
            label: {
              y: -12,
              x: -0.5,
              textAlign: 'center',
              useHTML: true,
              rotation: 0,
              text: '<div class="grid justify-items-center"><div class="text-xs font-medium px-2 text-gray-500 dark:text-gray-400">Copilot first adopted</div><div class="bg-gray-500 dark:bg-gray-400 w-2 h-2 rounded-full"></div>'
            }
          }
        ]
      }
    } else if (controller.optionsValue.type === 'column') {
      categories = controller.parsedGraphData.column_categories
      data = controller.parsedGraphData.column_data
      total = data.reduce((acc, item) => acc + item.y, 0)
      series = formatColumnSeries(data, colorConfigOptions(this))
    }

    let type = controller.optionsValue.type
    if (controller.optionsValue.format === 'step' && type === 'spline') type = 'line'
    const isSparklineGraph = !!controller.optionsValue.sparkline

    const maxSettings = this.getYAxisMaxAndTickAmountSettings(total)
    maxSettings.maxPadding = undefined

    return {
      chart: {
        type,
        backgroundColor: controller.colorTheme.backgroundColor,
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: controller.parsedGraphData.copilot_adoption_date_index ? 10 : 0,
        spacingBottom: 10
      },
      ...this.baseConfigOptions,
      xAxis: {
        type: 'category',
        categories,
        tickPositions: getTickPositions(categories?.length || 0),
        title: { text: null },
        tickLength: 0,
        labels: {
          enabled: isSparklineGraph === false,
          autoRotation: false,
          useHTML: true,
          formatter: function () {
            if (controller.optionsValue.type === 'column') {
              let date = ''

              if (this.value === 'Using Copilot') {
                date = `${controller.parsedGraphData.copilot_start_date} - ${controller.parsedGraphData.copilot_end_date}`
              } else if (this.value === 'All developers') {
                date = `${controller.parsedGraphData.baseline_start_date} - ${controller.parsedGraphData.baseline_end_date}`
              }

              return `<div class="text-center">
                <div class="text-xs font-medium text-gray-900 dark:text-white">${this.value}</div>
                <div class="text-xs text-gray-500 dark:text-gray-400 whitespace-nowrap">${date}</div>
              </div>`
            } else {
              return `<div class="text-center"><div class="text-xs font-medium text-gray-500 dark:text-gray-400">${this.value}</div></div>`
            }
          },
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        plotLines,
        lineWidth: 0
      },
      yAxis: {
        title: { text: null },
        gridLineWidth: isSparklineGraph ? 0 : 1,
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[700] : controller.tailwindColors.gray[200],
        labels: {
          enabled: isSparklineGraph === false,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            if (this.value !== 0 && !total) {
              return '--'
            } else {
              return this.value
            }
          }
        },
        ...maxSettings
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
          groupPadding: 0.1,
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5,
          borderColor: 'transparent',
          minPointLength: 5,
          colorByPoint: true,
          maxPointWidth: 150,
          dataLabels: {
            enabled: isSparklineGraph === false,
            crop: false,
            overflow: 'justify',
            formatter: function () {
              return controller.formatValue(this.y)
            },
            style: {
              color: controller.darkMode
                ? controller.tailwindColors.white
                : controller.tailwindColors.black
            }
          }
        },
        series: {
          connectNulls: true
        },
        spline: {
          connectNulls: true,
          marker: {
            enabled: false,
            symbol: 'circle'
          }
        },
        line: {
          marker: {
            enabled: false,
            symbol: 'circle'
          },
          step: 'center'
        }
      },
      colors: this.breakdownColors,
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        shared: true,
        formatter: function () {
          if (controller.optionsValue.type === 'spline') {
            const breakDownValues = this.points.reverse().map((p) => {
              const color = p.color.pattern ? p.color.pattern.color : p.color
              return breakDownValue({
                name: p.series.name,
                value: controller.formatValue(p.y),
                style: `background-color: ${color};`,
                type: 'box'
              })
            }).reverse()
            return card({
              date: this.x,
              breakDownValues
            })
          } else {
            return `<div class="shadow-lg p-3 rounded-md bg-white dark:bg-gray-700 min-w-[13rem] z-50">
              <div class="font-medium text-gray-700 dark:text-gray-300">${controller.optionsValue.title}</div>
              <div class="flex justify-between items-center gap-2 mt-3">
                <div class="flex items-center gap-1">
                  <div class="w-3 h-3 rounded-sm" style="background-color:${this.point.color}"></div>
                  <div class="font-medium text-black dark:text-white">${this.point.name}</div>
                </div>
                <div class="font-semibold text-black dark:text-white">${controller.formatValue(this.y)}</div>
              </div>
            </div>
            `
          }
        }
      }
    }
  }

  formatValue (value) {
    if (this.optionsValue.comparison_unit === 'day') {
      return value ? this.daysOrHoursString(value) : '-- days'
    } else if (this.optionsValue.comparison_unit === '%') {
      return `${this.formatNumber(value)}%`
    } else if (this.optionsValue.comparison_unit === '$') {
      return value ? this.USDFormatter.format((value / 100)) : '$--'
    } else if (this.optionsValue.comparison_unit) {
      return `${this.formatNumber(value)} / ${this.optionsValue.comparison_unit}`
    } else if (this.optionsValue.date_granularity) {
      return `${this.formatNumber(value)} per ${this.optionsValue.date_granularity.toLowerCase()}`
    } else {
      return this.formatNumber(value)
    }
  }

  formatNumber (value) {
    if (value === null || value === undefined) return '--'

    if (Number(value) === value && (value % 1 !== 0 || this.optionsValue.precision)) {
      return (Math.round(value * 10) / 10).toFixed(this.optionsValue.precision)
    } else {
      return value
    }
  }
}
